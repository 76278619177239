<template>
  <div>
    <div class="container-fluid">
      <PageTitle :noAdd="true" />
      <ul class="nav nav-tabs customtab" role="tablist">
        <li class="nav-item"> <a class="nav-link active" id="main-nav" data-toggle="tab" href="#main" role="tab" @click="changeTab('main')"><span
              class="hidden-sm-up"></span> <span class="hidden-xs-down">Contact Us</span></a> </li>
        <li class="nav-item"> <a class="nav-link" id="seo-nav" data-toggle="tab" href="#seo" role="tab" @click="changeTab('SEO')"><span
              class="hidden-sm-up"></span> <span class="hidden-xs-down">SEO Setting</span></a> </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane fade show active" id="main" role="tabpanel">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Hero Image</h5>
              <small>Last updated : {{(row.ahi_update_date||"").dates("format")}}</small>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="wrap_slider_img">
                    <img
                      :src="uploader((row.ahi_background || '').removeWebp(),'411')"
                      class="img-responsive" />
                    <div class="slider_name">
                      <p># {{row.ahi_title}}</p>
                    </div>
                    <div class="bullet-cta">
                      <a data-toggle="collapse" v-tooltip="'Edit Hero Image'" @click.prevent="openHero()" class="bullet_edit finger"><i class="fas fa-pencil-alt"></i></a>
                    </div>
                  </div>
                </div>
              </div>

              <ValidationObserver v-if="isHero" v-slot="{ handleSubmit }" ref="VForm">
                <form @submit.prevent="handleSubmit(doSubmitHero)">
                  <div class="row mt-5">
                    <div class="col-md-8 mb-3">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="panel_group">
                            <div class="panel_heading">Hero Image Text</div>
                            <div class="panel_body">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label class="control-label">Title <span class="text-danger">*</span></label>
                                    <input v-model="row.ahi_title" type="text" id="firstName" class="form-control"
                                      placeholder="Page Title">

                                    <VValidate name="Hero Title" v-model="row.ahi_title" rules="required" />
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label class="control-label">Description <span class="text-danger">*</span></label>
                                    <textarea class="form-control" v-model="row.ahi_description" rows="2"
                                      placeholder="Page Description">

                                    </textarea>
                                  </div>
                                  <VValidate name="Hero Description" v-model="row.ahi_description" rules="required" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <Uploader v-model="row.ahi_background" type="hero" label="Foto" />
                      <VValidate name="Hero Image" v-model="row.ahi_background" rules="required" />
                      <div class="form-group mt-3">
                        <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                        <input type="text" v-model="row.ahi_alt" class="form-control" placeholder="Alt Image">
                          <VValidate name="Alt Image Hero" v-model="row.ahi_alt" rules="required" />
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-end">
                    <div class="col-4 mt-3">
                      <button type="submit" class="btn btn-rounded btn-block btn-info">Submit</button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Contact Address</h5>
              <small>Last updated : {{(desc.sw_update_date||"").dates("format2")}}</small>
            </div>
            <div class="card-body">
                <!-- Column -->
              <!--
              <ValidationObserver v-slot="{ handleSubmit }" ref="VFormContact">
                <form @submit.prevent="handleSubmit(doSubmitCont)">
                
                </form>
              </ValidationObserver>
              -->
              <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
                <form @submit.prevent="handleSubmit(doSubmitAddress)">
                  
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label">Description <span class="text-danger">*</span></label>
                            <div class="input-group">
                              <textarea v-model="desc.sw_description" type="text" rows="5" class="form-control"
                                placeholder="Description"></textarea>
                            </div>

                            <VValidate name="Description" v-model="desc.sw_description" rules="required" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label">Email <span class="text-danger">*</span></label>
                            <input v-model="email.sw_description" type="text" class="form-control" placeholder="Email" />
                            <VValidate name="Email" v-model="email.sw_description" rules="required|email" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <label class="control-label">Have Address? <span class="text-danger">*</span></label>
                  <div class="d-flex">
                    <div class="custom-control custom-radio" style="margin-right: 10px;">
                      <input type="radio" name="radioRow1" id="Yes1" v-model="isAddress" :value="'Y'" class="custom-control-input" />
                      <label for="Yes1" class="custom-control-label">Yes</label>
                    </div>
                    <div class="custom-control custom-radio">
                      <input type="radio" name="radioRow1" id="No1" v-model="isAddress" :value="'N'" class="custom-control-input" />
                      <label for="No1" class="custom-control-label">No</label>
                    </div>
                  </div>
                  <div class="row mt-4">
                  <template v-if="isAddress == 'Y'">
                    <div class="col-md-4 mb-3" v-for="(v,k) in address" :key="k">
                      <div class="panel_group">
                        <div class="panel_heading">Contact Address {{k+1}}
                        </div>
                        <div class="panel_body">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label class="control-label">Title <span class="text-danger">*</span></label>
                                <input v-model="v.country" type="text" id="firstName" class="form-control"
                                  placeholder="Title">
                                <VValidate :name="`Title`" v-model="v.country"
                                  rules="required" />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label class="control-label">Address <span class="text-danger">*</span></label>
                                <textarea class="form-control" v-model="v.address" rows="3"
                                  placeholder="Address">
                                </textarea>
                                <VValidate :name="`Address`" v-model="v.address"
                                  rules="required" />
                              </div>
                            </div>
                            <div class="col-12 text-right">
                              <button class="btn btn-sm btn-rounded btn-danger" v-if="address.length>1" @click="address.splice(k,1)"><i class="ti-trash"></i> Delete</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 mb-5">
                      <a 
                        class="wrap_upload" style="cursor:pointer"
                        role="button" aria-expanded="false" href="#collapseForm"
                        @click.prevent="addAddress()"
                      >
                        <div class="ic_wrap">
                          <i class="fas fa-plus"></i>
                          <p>New Address</p>
                        </div>
                      </a>
                    </div>
                  </template>
                  <div class="col-12 mt-3 text-right">
                    <button type="submit" class="btn btn-rounded btn-info">Submit</button>
                  </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="seo" role="tabpanel">
          <div class="card" v-if="isMeta">
            <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
              <form @submit.prevent="handleSubmit(doSubmitMeta)">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label">Meta Title <a
                          v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                          class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                      <input type="text" v-model="staticSeo.sss_title" class="form-control"
                        placeholder="Meta Title">
                      <VValidate name="Meta Title" v-model="staticSeo.sss_title" :rules="{required: 1}" />
                    </div>

                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="" class="control-label">
                        Meta Keyword
                        <a class="help_info"
                          v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                          ?
                        </a>
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input-tag v-model="staticSeo.sss_keyword"></input-tag>
                      <VValidate name="Keyword" rules="required" v-model="staticSeo.sss_keyword" />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label">Meta Description <a
                          v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                          class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                      <textarea v-model="staticSeo.sss_desc" class="form-control" rows="4"></textarea>

                      <VValidate name="Meta Description" v-model="staticSeo.sss_desc" :rules="{required: 1}" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="form-actions text-right">
                  <button @click.prevent="doResetSEO" type="button"
                    class="btn  btn-rounded  btn-secondary">Cancel</button>
                  <button type="submit" class="btn  btn-rounded  btn-info">Submit</button>
                </div>
              </div>

            </form>
            </ValidationObserver>
          </div>
          <div class="card" v-else>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 text-right">
                  <label class="text-primary">Last updated : {{(staticSeo.sss_update_date||"").dates('format2')}}</label>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">Meta Title <a
                        v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                        class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                        <p>{{staticSeo.sss_title}}</p>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="" class="control-label">
                      Meta Keyword
                      <a class="help_info"
                        v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                        ?
                      </a>
                      <span class="text-danger mr5">*</span>
                    </label>
                    <div></div>
                    <template v-for="(v,k) in staticSeo.sss_keyword" >
                      <label class="label label-success mr-1" :key="k">
                        {{v}}
                      </label>
                    </template>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">Meta Description <a
                        v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                        class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                    <p>{{staticSeo.sss_desc}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="form-actions text-right">
                <a href="javascript:;" @click="editMeta()" class="btn btn-rounded btn-info">Edit</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import GlobalVue from '@libs/Global'
  import PageTitle from '@view/include/PageTitle'
  import Gen from '@libs/Gen.js'

  let $ = global.jQuery
  let _ = global._

  export default {
    extends: GlobalVue,
    components: {
      PageTitle
    },
    data() {
      return {
        other_options: {
          menubar: false
        },
        dataModal: {},
        isModalOpen: false,
        address: {},
        embedMap: "",
        isAddress: '',
        idEmbedMap: null,
        desc: {},
        buttonCoba: '',
        email: {},
        staticSeo: {},
        rowSEOtemp: {},
        isHero: false,
        isSearch: false,
        total_data: '',
        isFormMapDisabled:  true,
        isMeta:false,
        tab: '',
        TabSeo : JSON.parse(localStorage.getItem("SEO"))
      }
    },
    mounted() {
      this.apiGet()
      this.apiGetHeroImage()


      if(this.TabSeo !== null && this.TabSeo.menu === this.$route.name && this.TabSeo.val === "Y"){
        this.tab = 'SEO'
        setTimeout(()=>{
          $("#main").removeClass("active").removeClass("show");
          $("#seo").addClass("active").addClass("show");

          $("#main-nav").removeClass("active");
          $("#seo-nav").addClass("active");
        },200)
      }

      if(this.filter.search) this.isSearch = true
      setTimeout(() => {
        this.rowSEOtemp = this.staticSeo
        this.doResetSEO();
      }, 300)
    },
    methods: {
      apiGet(params = {}){
        if(this.pageSlug) this.loadingOverlay = true
        this.data.data = false
        Gen.apiRest(
          "/get/"+this.modulePage+
          (this.pageSlug?"/"+this.pageSlug:"")+
          (this.pageId?"/"+this.pageId:""), 
          {
            params: Object.assign({}, this.apiParams, params.query||{})
          }
        ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
          if(!this.isList){
            this.setFocusForm()
          }
          this.desc.sw_description =  res.data.desc.sw_description.replace(/[<]br[^>]*[>]/gi,"")
          if(params.callback) params.callback()
        }).catch(()=>{
          this.loadingOverlay = false
        })
      },
      doSubmitCont(){
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateDesc',
          }),
          (type, resp) => {
            this.apiGet()
            if (type == 'success') {
              this.isFormMapDisabled =  true
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                }) 
              }
            }
          },
        )
      },
      changeTab(tab){
        this.tab = tab
        if(this.tab === "SEO"){
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "Y"})
          localStorage.setItem("SEO", confSeo)
        }else{
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "N"})
          localStorage.setItem("SEO", confSeo)
        }
      },
      editMeta(){
        this.rowSEOtemp = this.staticSeo
        this.isMeta = true
      },
      doSearch (){
        this.isSearch = true
      },
      clsSearch(){
        this.isSearch = false
      },
      setModal(data) {
        this.dataModal = data
        Gen.apiRest(
          "/do/"+this.modulePage,
          {data: {
            type: "updateStatus",
            id: data.sc_id
          }},
          "POST"
        )
          .then(() => {
            this.apiGetNoOverlay()
          })
        this.isModalOpen = true
      },
      openHero() {
        this.isHero = !this.isHero;
      },
      apiGetHeroImage() {
        if (this.pageSlug) this.loadingOverlay = true

        this.data.data = false
        Gen.apiRest(
          "/get/" + "HeroImageContact"
        ).
        then(res => {
            this.loadingOverlay = false
            _.forEach(res.data, (v, k) => {
              this.$set(this, k, v)
            })

          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },
      addAddress(){
        this.address.push({country:null,address:null});
      },
      doSubmitAddress() {
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateAddress',
            isActive: this.isAddress,
            desc: this.desc.sw_description,
            email:this.email.sw_description,
          },{
            row: this.address
          }),
          (type, resp) => {
            this.apiGet()
            if (type == 'success') {
              this.apiGet()
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                }) 
              }
            }
          },
        )
      },
      
      doBackSuccessMeta(){
        this.tab = "main"
        $("#seo").removeClass("active").removeClass("show");
        $("#main").addClass("active").addClass("show");

        $("#seo-nav").removeClass("active");
        $("#main-nav").addClass("active");

        let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
        localStorage.setItem("SEO", confSeo)
      },
      
      doSubmitMeta() {
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateMeta',
            id: this.staticSeo.sss_id
          }, this.staticSeo),
          (type, resp) => {
            if (type == 'success') {
              this.isMeta = false
              this.staticSeo = resp.data
              this.doBackSuccessMeta()
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
        )
      },
      doSubmitHero() {
        this.row.ahi_update_by = this.user.id;
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateHeroImage',
            id: this.row.ahi_id
          }, this.row),
          (type, resp) => {
            if (type == 'success') {
              this.isHero = false
              $('#collapseExample').removeClass('show')
              window.scrollTo(0,0)
              this.row = resp.data
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
        )
      },
      apiGetNoOverlay(params = {}){
        Gen.apiRest(
          "/get/"+this.modulePage+
          (this.pageSlug?"/"+this.pageSlug:"")+
          (this.pageId?"/"+this.pageId:""), 
          {
            params: Object.assign({}, this.apiParams, params.query||{})
          }
        ).then(res=>{
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
          
          if(!this.isList){
            this.setFocusForm()
          }
        })
      },
      doResetSEO(){
        setTimeout(() => {
          this.staticSeo = {...this.rowSEOtemp}
        }, 0)
        
      },
      updateEmail(v){
        if(!v) return
        return this.doSubmitnoSwal(
          "/do/" + this.modulePage,_.assign({type: 'updateEmail',id: this.email.sw_id,email: v}, this.email, {email: this.email.sw_description}),
          (type, resp) => {
          return resp;
          },
        )
      },
      openMapForm(){
        this.isFormMapDisabled = !this.isFormMapDisabled
      },
      isHaveButton(e){
        setTimeout(() => {          
          this.buttonCoba = e.target.value
        }, 0) 
      }
    },
    watch: {
      $route() {
        this.apiGet()
        setTimeout(() => {
          this.rowSEOtemp = this.staticSeo
          this.doResetSEO();
        }, 300)
      },
      '$route.params.pageSlug':function(){
        this.$set(this,'filter',{})
      },
      'row.ahi_title'(v){
        this.row.ahi_alt = v
      },
    }
  }
</script>